import React, { useEffect, useState } from 'react';

import { Link, useLocation, useParams } from "react-router-dom";
import profile from "../../../img/admin-avatar.png";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import LogoutPage from './Logout';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsAction, setLocationIdAction } from '../../../store/actions/LocationActions';
import { selectLocationOptions } from '../../../store/selectors/LocationSelectors';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import LocationModal from '../../modals/LocationModal';
import { addLocation, getSquareLocations } from '../../../services/LocationService';
import swal from "sweetalert";
import { defaultPeriods } from '../../../services/UtilsService';

const Header = ({ isSubAdmin }) => {
  const dispatch = useDispatch();
  const q = new URLSearchParams(useLocation().search);
  const locationOptions = useSelector(selectLocationOptions);
  const { selectedLocationId } = useSelector(state => state.location);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [silentLoading, setSilentLoading] = useState(false);
  const [showMobileLocation, setShowMobileLocation] = useState(false);
  const [editLocationData, setEditLocationData] = useState(null);
  const [hideLocation, setHideLocation] = useState(false);

  useEffect(() => {
    if(q.get('loc')) {
      dispatch(getLocationsAction(q.get('loc')));
    } else {
      dispatch(getLocationsAction());
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('users')
      || window.location.href.includes('rewards')
      || window.location.href.includes('discount')
      || window.location.href.includes('admins')
      || window.location.href.includes('notifications')) {
      setHideLocation(true);
    } else {
      setHideLocation(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (!!selectedLocationId && selectedLocationId !== "all") {
      setSilentLoading(true);
      setTimeout(() => {
        setSelectedOption(locationOptions.find(option => option.value === selectedLocationId));
        // setSelectedOption({label: "All", value: "all"})
        setSilentLoading(false);
      }, 300);
    }
  }, [locationOptions]);

  useEffect(() => {
    setLoading(true);
    setSilentLoading(true);
    setSelectedOption(selectedLocationId == "all" ? {label: "All", value: "all"} : locationOptions.find(option => option.value === selectedLocationId));  
    setLoading(!selectedLocationId);
    setSilentLoading(false);
  }, [selectedLocationId]);

  const handleMobileLocationClick = () => {
    setShowMobileLocation(!showMobileLocation);
  };

  const changeLocation = (option) => {
    console.log(option)
    if (option.value) {
      setSelectedOption(option);
      const locationId = option.value;
      dispatch(setLocationIdAction(locationId));
    } else {
      handleAddLocation();
    }
  };

  const handleAddLocation = () => {
    const locationData = {
      address: {
        country: "United States"
      },
      displayAddress: "",
      coordinates: [],
      addNew: true,
      image: ""
    };
    setEditLocationData({ ...locationData });
  }

  const handleSaveLocationAddress = (updatedLocationData) => {
    setLoading(true);
    const request = {
      squareLocationId: updatedLocationData.squareLocationId,
      name: updatedLocationData.displayAddress,
      address: {
        addressLine1: updatedLocationData.address?.addressLine1,
        city: updatedLocationData.address?.city,
        state: updatedLocationData.address?.state,
        postalCode: updatedLocationData.address?.postalCode,
        country: updatedLocationData.address?.country,
      },
      latitude: updatedLocationData.coordinates?.length ? updatedLocationData.coordinates[1] : null,
      longitude: updatedLocationData.coordinates?.length ? updatedLocationData.coordinates[0] : null,
      country: updatedLocationData.address?.country,
      capabilities: [
        "Coffee"
      ],
      phoneNumber: "+1 28356265",
      image: updatedLocationData.image,
      businessEmail: "birch@gmail.com",
      twitterUsername: "birch-coffee",
      instagramUsername: "birch@coffee",
      facebookUrl: "birch-coffee@facebook.com",
      periods: defaultPeriods,
      languageCode: "Eng",
      currency: "USD",
      businessName: "Birch Coffee"
    };
    if (updatedLocationData?.locationId) {
      request['locationId'] = updatedLocationData.locationId;
    }
    addLocation(request).then(response => {
      console.log("add location response", response);
      const responseData = response.data?.data;
      // dispatch(getLocationsAction(responseData._id));
      window.location.href = "?loc=" + responseData._id;
      swal('Good job!', 'Successfully Added', "success");
    }).catch(error => {
      console.error(error);
      swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
      setLoading(false);
    });
    setEditLocationData(null);
  };
  const handleCancelLocationAddress = () => {
  };

  const finalLocationOptions = () => {
    if (isSubAdmin) {
      return locationOptions;
    }

    return [ ...locationOptions, { label: "Add new location", value: null }];
  }

  return (
    <div className="header" style={{ zIndex: "11" }}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            {!hideLocation &&
              <div className="header-left">
                <div className={"search_bar dropdown show " + (showMobileLocation ? 'mobile-show' : '')}>
                  <div className="dropdown-menu p-0 m-0 show">
                    <form>
                      {loading && <Spinner animation="border" variant="primary" />}
                      {!loading &&
                        <>
                          {!!selectedOption && !editLocationData && silentLoading &&
                            <Select
                              className="location-select"
                              defaultValue={{ label: "All", value: "all" }}
                              onChange={changeLocation}
                              options={[{ label: "All", value: "all" },...finalLocationOptions()]}
                              placeholder="Select location"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  text: 'white',
                                  primary25: '#ffeae6',
                                  primary: '#FF3333',
                                },
                              })}
                            />
                          }
                          {!!selectedOption && !editLocationData && !silentLoading &&
                            <Select
                              className="location-select"
                              defaultValue={{ label: "All", value: "all" }}
                              onChange={changeLocation}
                              options={[{ label: "All", value: "all" },...finalLocationOptions()]}
                              placeholder="Select location"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  text: 'white',
                                  primary25: '#ffeae6',
                                  primary: '#FF3333',
                                },
                              })}
                            />
                          }
                          {!selectedOption && <Button onClick={handleAddLocation}>Add Location</Button>}
                        </>
                      }
                    </form>
                  </div>
                </div>
              </div>
            }
            <ul className="navbar-nav header-right ml-auto">
              {!hideLocation &&
                <Link to="#" as="li"
                  className="nav-item dropdown location-dropdown" onClick={handleMobileLocationClick}>
                  <div className="nav-link i-false c-pointer ai-icon d-flex align-items-center d-sm-none d-md-none d-lg-none d-block">
                    <span>{selectedOption?.label}</span>
                    <i className={"las fs-24 " + (!showMobileLocation ? 'la-map-pin' : 'la-times')}></i>
                  </div>
                </Link>
              }
              {/* <Link to="#" as="li"
                className="nav-item dropdown notification_dropdown">
                <div className="nav-link i-false c-pointer ai-icon davur-notification warning">
                  <i className="las la-question-circle"></i>
                </div>
              </Link> */}
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="i-false c-pointer nav-link"
                  to=""
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-info">
                    <span>
                      Hello, <strong>{isSubAdmin ? 'Sub-admin' : 'Admin'}</strong>
                    </span>
                  </div>
                  <img src={profile} width={20} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="dropdown-menu dropdown-menu-right"
                >
                  <Link to="#" className="dropdown-item ai-icon" onClick={() => setShowChangePasswordModal(true)}>
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Change Password </span>
                  </Link>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
      <ChangePasswordModal showModal={showChangePasswordModal} cancel={() => { setShowChangePasswordModal(false) }}></ChangePasswordModal>
      <LocationModal editLocationData={editLocationData} setEditLocationData={setEditLocationData} save={handleSaveLocationAddress} cancel={handleCancelLocationAddress}></LocationModal>
    </div>
  );
};

export default Header;
